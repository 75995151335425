"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchThroughBff = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const auth_service_1 = require("./auth-service");
const constants_1 = require("./constants");
function fetchThroughBff(url, options) {
    const newOptions = options ? options : { headers: {} };
    const newUrl = url.indexOf(constants_1.BFF_PRIFIX) !== -1 ? url : parseUrl(url);
    newOptions["credentials"] = "include";
    newOptions.headers["x-csrf"] = "1";
    // set applicationCode if not exist
    if (newOptions.headers["applicationCode"] === undefined) {
        newOptions.headers["applicationCode"] =
            auth_service_1.AuthService.Instance.getApplicationCode();
    }
    const promise = new Promise((resolve, reject) => {
        fetchCall(resolve, reject, newUrl, newOptions);
    });
    return promise;
}
exports.fetchThroughBff = fetchThroughBff;
function fetchCall(resolve, reject, newUrl, newOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(newUrl, newOptions);
            if ((response.status === 403 || response.status === 401) &&
                response.headers.has("x-bff-logout") &&
                response.headers.get("x-bff-logout") === "true") {
                auth_service_1.AuthService.Instance.logout();
            }
            resolve(response);
        }
        catch (e) {
            console.log("fetchCall Error ", e);
            reject(e);
        }
    });
}
function parseUrl(url) {
    const components = url.split("/");
    const newUrl = components[2] + constants_1.BFF_PRIFIX;
    return url.replace(components[2], newUrl);
}
