"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExtensionsList = exports.appendBffPrefix = void 0;
const constants_1 = require("../constants");
function appendBffPrefix(url) {
    const components = url.split("/");
    const newUrl = components[2] + constants_1.BFF_PRIFIX;
    return url.replace(components[2], newUrl);
}
exports.appendBffPrefix = appendBffPrefix;
function getExtensionsList(fileManagementSettings) {
    var _a, _b, _c;
    let extensionList = [];
    const applicationFileExtensions = ((_a = fileManagementSettings === null || fileManagementSettings === void 0 ? void 0 : fileManagementSettings.applicationFileExtensions) === null || _a === void 0 ? void 0 : _a.map((element) => { var _a; return (_a = element.extension) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(); })) || [];
    const customFileExtensions = ((_b = fileManagementSettings === null || fileManagementSettings === void 0 ? void 0 : fileManagementSettings.customFileExtensions) === null || _b === void 0 ? void 0 : _b.map((element) => { var _a; return (_a = element.extension) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(); })) || [];
    const thermoDefaultFileExtentions = ((_c = fileManagementSettings === null || fileManagementSettings === void 0 ? void 0 : fileManagementSettings.thermoDefaultFileExtentions) === null || _c === void 0 ? void 0 : _c.map((element) => { var _a; return (_a = element.extension) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(); })) || [];
    extensionList = [...applicationFileExtensions, ...customFileExtensions, ...thermoDefaultFileExtentions];
    return extensionList;
}
exports.getExtensionsList = getExtensionsList;
