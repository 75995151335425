"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = void 0;
class StorageService {
    static get instance() {
        if (!StorageService.__instance) {
            StorageService.__instance = new StorageService();
        }
        return StorageService.__instance;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPageNavigationData(data) {
        this.pageNavigationData = data;
    }
    getPageNavigationData() {
        return this.pageNavigationData;
    }
}
exports.StorageService = StorageService;
