"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionTimerService = void 0;
const auth_service_1 = require("./auth-service");
const TIME_BEFORE_SESSION_EXPIRE = 180 * 1000;
class SessionTimerService {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._timerInstance = null;
    }
    static get instance() {
        if (!SessionTimerService.__instance) {
            SessionTimerService.__instance = new SessionTimerService();
        }
        return SessionTimerService.__instance;
    }
    startSessionTimer() {
        return __awaiter(this, void 0, void 0, function* () {
            let timeRemaining = yield auth_service_1.AuthService.Instance.getSessionTimeout(false);
            let timerInterval = null;
            if (this._timerInstance || !timeRemaining) {
                this.stopSessionTimer();
            }
            if (timeRemaining) {
                timerInterval = timeRemaining;
                this._timerInstance = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                    timeRemaining = yield auth_service_1.AuthService.Instance.getSessionTimeout(true);
                    if (timeRemaining) {
                        timerInterval = timeRemaining;
                    }
                }), timerInterval * 1000 - TIME_BEFORE_SESSION_EXPIRE);
            }
        });
    }
    stopSessionTimer() {
        this._timerInstance = null;
        clearInterval(this._timerInstance);
    }
}
exports.SessionTimerService = SessionTimerService;
