"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformService = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const constants_1 = require("./constants");
const main_1 = require("./main");
const bff_service_1 = require("./bff-service");
class PlatformService {
    static get Instance() {
        if (!PlatformService._instance) {
            PlatformService._instance = new PlatformService();
        }
        return PlatformService._instance;
    }
    getAppSpaceUserPreferences(sourceId = constants_1.SOURCE_APPLICATION_ID, apiUrl // TODO: remove this parameter once refactor apiUrl, appCode under one setting
    ) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = (apiUrl ||
                main_1.ApplicationDataService.Instance.getApiURL()) +
                (0, constants_1.APPSPACE_USER_PREFERENCES)(sourceId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    updateAppSpaceUserPreferences(preference, sourceId = constants_1.SOURCE_APPLICATION_ID, apiUrl // TODO: remove this parameter once refactor apiUrl, appCode under one setting
    ) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = (apiUrl ||
                main_1.ApplicationDataService.Instance.getApiURL()) +
                (0, constants_1.APPSPACE_USER_PREFERENCES)(sourceId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                body: JSON.stringify(preference),
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok;
            }));
        });
    }
    getUserApplications() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.APPSPACE_GET_ALL_APPLICATIONS)();
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    getApplicationByName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() +
                (0, constants_1.APPSPACE_GET_APPLICATION_DETAIL)(name);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                if (res.ok) {
                    const data = yield res.json(); // application detail as first item in an array
                    return data[0] || null;
                }
                else {
                    return null;
                }
            }));
        });
    }
    getGlobalSettings(application, category) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = main_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.GLOBAL_USER_SETTINGS)(application, category);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: 'get',
                headers: main_1.ApplicationDataService.Instance.getRequestHeaders(),
            })
                .then(main_1.ApplicationDataService.Instance._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? res.json() : [];
            }));
        });
    }
    getApplicationIconURL(appId, quickAccessIconURL) {
        return (main_1.ApplicationDataService.Instance.getApiURL() +
            (0, constants_1.APPSPACE_GET_QUICK_ACCESS_ICON_URL)(appId, quickAccessIconURL));
    }
    setCurrentOpenedApp(app) {
        this.currentApp = app;
    }
    getCurrentOpenedApp() {
        return this.currentApp;
    }
}
exports.PlatformService = PlatformService;
