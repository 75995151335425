"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiUrlService = void 0;
const applicationDataService_1 = require("./applicationDataService");
const ApiUrlConstants = __importStar(require("./constants"));
class ApiUrlService {
    constructor() {
        this._baseApiUrl = {
            apiUrl: ``,
            hostName: ``,
        };
        this._identityUrl = {
            apiUrl: ``,
            hostName: ``,
        };
        this._platformUrl = "";
        this._setPlatformUrl();
        this._setBaseAPIUrls();
        this._setIdentityProviderUrl();
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    get baseApiUrl() {
        const hostname = window.location.hostname;
        // added hostname null check as it's not avaliable non web base application, 
        // need to figure out better approach in future
        if (hostname === ApiUrlConstants.LOCALHOST || !hostname) {
            const apiUrl = applicationDataService_1.ApplicationDataService.Instance.getApiURL();
            if (apiUrl) {
                this._baseApiUrl.apiUrl = apiUrl;
                this._baseApiUrl.hostName = apiUrl.split("//")[1];
            }
        }
        return this._baseApiUrl;
    }
    set baseApiUrl(baseApiInfo) {
        if (baseApiInfo === null || baseApiInfo === void 0 ? void 0 : baseApiInfo.apiUrl) {
            this._baseApiUrl.apiUrl = baseApiInfo.apiUrl;
        }
        if (baseApiInfo === null || baseApiInfo === void 0 ? void 0 : baseApiInfo.hostName) {
            this._baseApiUrl.hostName = baseApiInfo.hostName;
        }
    }
    get platformUrl() {
        const hostname = window.location.hostname;
        const apiUrl = applicationDataService_1.ApplicationDataService.Instance.getApiURL();
        // added hostname null check as it's not avaliable non web base application, 
        // need to figure out better approach in future
        if ((hostname === ApiUrlConstants.LOCALHOST || !hostname) && apiUrl) {
            return apiUrl.replace("api.", "");
        }
        return this._platformUrl;
    }
    get identityUrl() {
        return this._identityUrl;
    }
    _setPlatformUrl() {
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        this._platformUrl = `${protocol}//${hostname}`;
    }
    _setBaseAPIUrls() {
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        this._baseApiUrl.apiUrl = `${protocol}//${ApiUrlConstants.API}.${hostname}`;
        this._baseApiUrl.hostName = `${ApiUrlConstants.API}.${hostname}`;
    }
    _setIdentityProviderUrl() {
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        if (hostname === ApiUrlConstants.LOCALHOST) {
            this._identityUrl.apiUrl = ApiUrlConstants.IDENTITY_CMDDEV;
            this._identityUrl.hostName = ApiUrlConstants.IDENTITY_CMDDEV_HOST;
        }
        else {
            this._identityUrl.apiUrl = `${protocol}//${ApiUrlConstants.IDENTITY}.${hostname}`;
            this._identityUrl.hostName = `${ApiUrlConstants.IDENTITY}.${hostname}`;
        }
    }
}
exports.ApiUrlService = ApiUrlService;
