"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadUtility = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const platform_service_1 = require("../platform-service");
const utils_1 = require("../utils");
/**
 * Utility class for handling file uploads.
 */
class FileUploadUtility {
    constructor(data) {
        this.allowedExtList = [];
        this.isAllFileAllowed = false;
        this._formatData(data);
    }
    _formatData(data) {
        const fileManagementSettings = data.settings[0].data;
        this.isAllFileAllowed = !(fileManagementSettings === null || fileManagementSettings === void 0 ? void 0 : fileManagementSettings.allowUploadOfFileExtensions);
        this.allowedExtList = (0, utils_1.getExtensionsList)(fileManagementSettings);
    }
    /**
     * Returns an instance of FileUploadUtility.
     * If an instance does not exist, it creates a new instance using the global settings obtained from PlatformService.
     * @returns The instance of FileUploadUtility.
     */
    static getInstance() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!FileUploadUtility.instance) {
                try {
                    const data = yield platform_service_1.PlatformService.Instance.getGlobalSettings("PLATFORM", "FILEMANAGEMENT");
                    FileUploadUtility.instance = new FileUploadUtility(data);
                }
                catch (error) {
                    console.error("Error getting global settings for file upload utility", error);
                }
            }
            return FileUploadUtility.instance;
        });
    }
    /**
     * Returns a boolean value indicating whether all file uploads are allowed.
     *
     * @returns {boolean} True if all file uploads are allowed, false otherwise.
     */
    isAllFileUploadAllowed() {
        return this.isAllFileAllowed;
    }
    /**
     * Checks if file upload is allowed based on the file extension.
     * @param fileExt - The file extension to check.
     * @returns True if file upload is allowed, false otherwise.
     */
    isFileUploadAllowed(fileExt) {
        const isUploadAllowed = this.isAllFileAllowed ? true : this.allowedExtList.includes(fileExt === null || fileExt === void 0 ? void 0 : fileExt.toLocaleLowerCase());
        return isUploadAllowed;
    }
    /**
     * Retrieves the list of allowed file extensions.
     *
     * @returns An array of strings representing the allowed file extensions.
     */
    getExtensionsList() {
        return this.allowedExtList;
    }
}
exports.FileUploadUtility = FileUploadUtility;
